import { Button } from '@components/ui'
import Link from 'next/link'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import { FC } from 'react'
import s from './IntroTextsV2.module.css'
interface props {
  primary: {
    background_colour?: string
    texts_colour?: string
    padding_top?: boolean
    mobile_padding_top?: boolean
    button_style: 'dark' | 'light'
    texts_and_button_position: 'left' | 'center'
    intro_title?: string
    intro_contents?: RichTextBlock[]
    intro_subtext?: RichTextBlock[]
  }
  applyWrapper?: boolean
  items?: {
    button_text?: string
    button_link?: string
  }[]
  index: number
}

const IntroTextsV2: FC<props> = ({ primary, items, index }) => {
  const {
    background_colour,
    texts_colour,
    padding_top,
    mobile_padding_top,
    button_style,
    texts_and_button_position,
    intro_title,
    intro_contents,
    intro_subtext,
  } = primary || {}
  const background = background_colour || '#FBF9F6'
  const textsColour = texts_colour || '#FFFFFF'
  const textAndButtonPosition = () => {
    if (texts_and_button_position === 'left') {
      return 'flex-start flex-wrap'
    }
    if (texts_and_button_position === 'center') {
      return 'justify-center'
    }
  }
  const paddingTop = padding_top ? '60px' : '0px'
  const paddingTopMobile = mobile_padding_top ? '40px' : '0px'
  const sliceIndex = index
  return (
    <div style={{ background: background }}>
      <div
        className={`wrapper pb-40 px-20 sectionPaddingTop-${sliceIndex} laptop:pb-72 laptop:px-0`}
        style={{ color: textsColour }}
      >
        {intro_title && (
          <div className="grid grid-cols-12">
            <h2
              className={`heading-3 col-span-12 laptop:col-span-8 laptop:col-start-3 laptop:heading-4 text-${texts_and_button_position} laptop:text-center`}
            >
              {intro_title}
            </h2>
          </div>
        )}
        {intro_contents && intro_contents?.find((text) => text?.text && text?.text?.length > 0) && (
          <div className="grid grid-cols-12">
            <div
              className={`pt-12 laptop:pt-20 col-span-12 laptop:col-span-8 laptop:col-start-3 font-g-regular text-16 leading-24 font-normal laptop:heading-6 introTexts text-${texts_and_button_position} laptop:text-center flex ${textAndButtonPosition()} laptop:justify-center`}
            >
              <RichText render={intro_contents} />
            </div>
          </div>
        )}
        {intro_subtext && intro_subtext?.find((text) => text?.text && text?.text?.length > 0) && (
          <div className={`intro-subtext-${sliceIndex} mt-14 laptop:mt-20 body text-center`}>
            <RichText render={intro_subtext} />
          </div>
        )}
        {items && (
          <div className={`flex flex-wrap gap-8 pt-28 laptop:pt-32 ${textAndButtonPosition()} laptop:justify-center`}>
            {items?.map((item, index) => {
              if (!item.button_link) return <></>
              return (
                <Link key={index} href={item?.button_link} passHref legacyBehavior>
                  <Button
                    size="small"
                    type="button"
                    variant="secondary"
                    className={s.button}
                    bg={button_style === 'dark' ? 'light' : 'dark'}
                    colorSchema={button_style}
                  >
                    {item?.button_text}
                  </Button>
                </Link>
              );
            })}
          </div>
        )}

        <style jsx>{`
          .intro-subtext-${sliceIndex} p {
            color: ${textsColour};
          }
          .intro-subtext-${sliceIndex} a {
            text-decoration: underline;
            color: ${textsColour};
          }
          .sectionPaddingTop-${sliceIndex} {
            padding-top: ${paddingTop};
          }
          @media (max-width: 425px) {
            .sectionPaddingTop-${sliceIndex} {
              padding-top: ${paddingTopMobile};
            }
          }
        `}</style>
      </div>
    </div>
  );
}

export default IntroTextsV2
